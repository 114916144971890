<template>
  <div style="padding: 20px;">
    <a-spin tip="加载中..." :spinning="products.spinning">
      <a-row :gutter="16">
        <a-col :span="24" :md="8" v-for="(item, index) in products.items" :key="index">
          <a-card :title="item.name" :bordered="false">
            <a-tag color="green" slot="extra" style="font-size: larger;">
              ¥{{ item.price }}
            </a-tag>
            <a-tag slot="extra" color="#108ee9" style="font-size: larger;">
              <a-tooltip>
                <template slot="title">
                  购买所得积分
                </template>
                {{ item.integral }}
              </a-tooltip>
            </a-tag>
            <a-tag v-if="!item.noCount" slot="extra">
              <a-tooltip>
                <template slot="title">
                  所剩库存
                </template>
                {{ item.stock }}
              </a-tooltip>
            </a-tag>
            <p>{{ item.description }}</p>
            <template slot="actions">
              <a-button size="small" type="primary" key="edit" @click="buy(item)">
                购买
              </a-button>
            </template>
          </a-card>
        </a-col>
      </a-row>
    </a-spin>
    <a-modal v-model="orderDialog.visible" title="订单确认" on-ok="order">
      <template slot="footer">
        <a-button key="submit" type="primary" :loading="orderDialog.loading" @click="order">
          确认
        </a-button>
        <a-button type="dashed" :loading="orderDialog.loading" @click="orderDialog.visible = false">
          取消
        </a-button>
      </template>
      <a-spin :spinning="orderDialog.loading">
        <a-descriptions :column="1">
          <a-descriptions-item label="商品名称">
            {{ products.currentItem.name }}
          </a-descriptions-item>
          <a-descriptions-item label="金额">
            {{ products.currentItem.price }} 元
          </a-descriptions-item>
          <!-- <a-descriptions-item label="余额抵扣">
          {{ balanceDeduction }}
        </a-descriptions-item> -->
          <a-descriptions-item label="所得积分">
            {{ products.currentItem.integral }}
          </a-descriptions-item>
          <a-descriptions-item label="付款方式">
            <a-radio-group v-model="orderDialog.payMethod" size="small">
              <a-radio-button value="AliF2F">
                支付宝
              </a-radio-button>
              <a-radio-button value="Balance">
                余额
              </a-radio-button>
            </a-radio-group>
          </a-descriptions-item>
        </a-descriptions>
      </a-spin>
    </a-modal>
    <a-modal v-model="qrCodeDialog.show" title="在线支付" style="text-align: center;">
      <template slot="footer">
        <a-button key="back" @click="qrCodeDialog.show = false">
          关闭
        </a-button>
      </template>
      <vue-qr :text="qrCodeDialog.url" :size="200"></vue-qr>
      <p>{{ qrCodeDialog.tip }}</p>
    </a-modal>
  </div>
</template>

<script>
import vueQr from 'vue-qr'

export default ({
  components: {
    vueQr,
  },
  data() {
    return {
      products: {
        items: [],
        currentItem: {},
        spinning: true,
      },
      orderDialog: {
        visible: false,
        loading: false,
        payMethod: 'AliF2F',
      },
      qrCodeDialog: {
        url: '',
        tip: '',
        show: false,
      },
    }
  },
  methods: {
    buy(item) {
      this.products.currentItem = item
      this.orderDialog.visible = true
    },
    order() {
      this.orderDialog.loading = true
      this.$axios.post('/api/platform/charge/pay', {
        payMethod: this.orderDialog.payMethod,
        productId: this.products.currentItem.id,
      }).then(res => {
        this.orderDialog.loading = false
        if (res.code != 0) {
          this.$message.error(res.msg)
          return
        } else {
          this.orderDialog.visible = false
        }
        const data = res.data
        if (data.showQrCode) {
          this.qrCodeDialog.show = data.showQrCode
          this.qrCodeDialog.tip = data.qrTip
          this.qrCodeDialog.url = data.qrCode
        }
      })
    }
  },
  mounted() {
    this.$axios.get('/api/platform/product')
      .then(res => {
        this.products.items = res.data
        this.products.spinning = false
      })
  },
  computed: {
    balanceDeduction() {
      return `余额${1} 元，还需支付${1} 元`
    }
  },
})

</script>

<style scoped>
.ant-card {
  margin-bottom: 20px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.ant-card p {
  height: 200px;
  overflow-y: auto;
}
</style>